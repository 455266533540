import React, { forwardRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeInput from './CodeInput';

// Fixed canvas size
const CANVAS_SIZE = {
  width: 800,
  height: 500
};

// Calculate dimensions to fit within canvas while maintaining aspect ratio
const calculateFitDimensions = (imageWidth, imageHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / imageWidth, maxHeight / imageHeight);
  return {
    width: imageWidth * ratio,
    height: imageHeight * ratio
  };
};

const templateStyles = {
  browser: {
    padding: '32px 16px 16px',
    borderRadius: '8px',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    maxWidth: '700px',
    width: '100%',
    height: 'fit-content',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ff5f57',
      boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
    },
    '& .imageContainer': {
      width: '100%',
      height: '100%',
      maxHeight: '400px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down',
      maxHeight: '400px',
    }
  },
  browserDark: {
    padding: '32px 16px 16px',
    borderRadius: '8px',
    backgroundColor: '#1E1E1E',
    position: 'relative',
    maxWidth: '700px',
    width: '100%',
    height: 'fit-content',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ff5f57',
      boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
      zIndex: 2,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '32px',
      backgroundColor: '#252525',
      borderRadius: '8px 8px 0 0',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      zIndex: 1,
    },
    '& .imageContainer': {
      width: '100%',
      height: '100%',
      maxHeight: '400px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down',
      maxHeight: '400px',
    }
  },
  code: {
    padding: '32px 16px 16px',
    borderRadius: '8px',
    backgroundColor: '#011627',
    position: 'relative',
    width: '100%',
    height: 'auto',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ff5f57',
      boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
      zIndex: 2,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '32px',
      backgroundColor: '#0b2942',
      borderRadius: '8px 8px 0 0',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      zIndex: 1,
    },
    '& .codeContainer': {
      width: '100%',
      height: 'auto',
      position: 'relative',
      zIndex: 1,
      '& pre': {
        margin: '0 !important',
        borderRadius: '0 0 8px 8px',
        height: 'auto !important',
        maxHeight: 'none !important',
        overflow: 'visible !important',
        whiteSpace: 'pre-wrap !important',
        wordBreak: 'break-word',
        padding: '16px !important',
      },
      '& code': {
        whiteSpace: 'pre-wrap !important',
        wordBreak: 'break-word',
      }
    }
  }
};

const Preview = forwardRef(({ image, options }, ref) => {
  const [imageDimensions, setImageDimensions] = useState(null);
  const [code, setCode] = useState('');
  const [codeHeight, setCodeHeight] = useState(200);

  // Update code height when code changes
  useEffect(() => {
    if (options.template === 'code' && code) {
      const lineCount = code.split('\n').length;
      const newHeight = Math.max(200, lineCount * (options.codeFontSize * 1.5));
      setCodeHeight(newHeight);
    }
  }, [code, options.codeFontSize, options.template]);

  // Calculate the maximum possible size while maintaining aspect ratio
  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        const maxWidth = CANVAS_SIZE.width * 0.9; // 90% of canvas width
        const maxHeight = CANVAS_SIZE.height * 0.9; // 90% of canvas height
        
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const ratio = Math.min(widthRatio, heightRatio);
        
        // Set initial size to maximum possible while maintaining aspect ratio
        const initialWidth = img.width * ratio;
        
        // Use the slider value to scale from this maximum size
        const scalePercentage = (options.imageSize || 300) / 300; // 300 is the default size
        const finalWidth = Math.min(initialWidth * scalePercentage, maxWidth);
        
        setImageDimensions({
          width: finalWidth,
          height: (finalWidth * img.height) / img.width
        });
      };
      img.src = image;
    }
  }, [image, options.imageSize]);

  const shadowStyle = options.shadowBlur || options.shadowSpread
    ? {
        filter: `drop-shadow(0 0 ${options.shadowBlur || 0}px ${options.shadowColor || '#000000'})`
      }
    : {};

  const getBorderColorWithOpacity = (hex, opacity = 1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const renderCodeTemplate = () => {
    return (
      <Box 
        className="codeContainer"
        sx={{
          height: 'auto',
          minHeight: '150px',
          transition: 'height 0.3s ease',
        }}
      >
        <CodeInput 
          onChange={(newCode) => {
            setCode(newCode);
          }}
          fontSize={options.codeFontSize || 14}
          language={options.codeLanguage || 'javascript'}
          showLineNumbers={options.showLineNumbers ?? true}
        />
      </Box>
    );
  };

  return (
    <Box sx={{
      width: options.canvasWidth,
      height: options.canvasHeight,
      position: 'relative',
      overflow: 'hidden',
      borderRadius: `${options.borderRadius}px`,
      border: `${options.borderWidth}px solid ${options.borderColor}`,
      boxShadow: `${options.shadowSpread}px ${options.shadowSpread}px ${options.shadowBlur}px ${options.shadowColor}`,
      background: options.gradient,
    }}>
      <Box
        ref={ref}
        sx={{
          width: '100%',
          height: 'auto',
          minHeight: options.template === 'code' ? 'auto' : '100%',
          background: options.gradient,
          display: 'flex',
          alignItems: options.template === 'code' ? 'flex-start' : 'center',
          justifyContent: 'center',
          padding: 2,
          overflow: 'visible',
        }}
      >
        <Box
          sx={{
            ...(options.template !== 'none' && templateStyles[options.template]),
            ...shadowStyle,
            position: 'relative',
            maxWidth: '90%',
            width: options.template === 'code' ? '100%' : 'auto',
            height: 'auto',
            display: 'flex',
            alignItems: options.template === 'code' ? 'flex-start' : 'center',
            justifyContent: 'center',
            overflow: 'visible',
          }}
        >
          {options.template === 'code' ? (
            renderCodeTemplate()
          ) : (
            <Box
              component="img"
              src={image}
              alt="Preview"
              sx={{
                width: imageDimensions?.width || 'auto',
                height: imageDimensions?.height || 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                borderRadius: options.template === 'none' ? `${options.borderRadius}px` : 'inherit',
                border: options.template === 'none' ? 
                  `${options.borderWidth}px solid ${getBorderColorWithOpacity(options.borderColor, options.borderOpacity)}` : 
                  'none',
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default Preview; 