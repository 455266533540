import React, { useState, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { 
  nightOwl,
  atomOneDark,
  dracula,
  vs,
  xcode,
  github,
  monokai,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const themes = {
  nightOwl,
  atomOneDark,
  dracula,
  vs,
  xcode,
  github,
  monokai,
};

function CodeInput({ onChange, fontSize = 14, language = 'javascript', showLineNumbers = true }) {
  const [code, setCode] = useState('');
  const textAreaRef = useRef(null);

  const handleCodeChange = (event) => {
    const newCode = event.target.value;
    setCode(newCode);
    onChange(newCode);
  };

  const handleClick = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const getLanguageAlias = (lang) => {
    // Map common language names to their aliases
    const languageMap = {
      'javascript': 'javascript',
      'python': 'python',
      'java': 'java',
      'cpp': 'cpp',
      'c++': 'cpp',
      'csharp': 'csharp',
      'c#': 'csharp',
      'ruby': 'ruby',
      'php': 'php',
      'swift': 'swift',
      'go': 'go',
      'rust': 'rust',
      'typescript': 'typescript',
      'sql': 'sql',
      'html': 'html',
      'css': 'css',
      'json': 'json',
      'yaml': 'yaml',
      'markdown': 'markdown',
      'bash': 'bash',
      'shell': 'shell',
    };
    return languageMap[lang.toLowerCase()] || lang;
  };

  return (
    <Box 
      sx={{ 
        width: '100%', 
        height: '100%',
        position: 'relative',
        cursor: 'text'
      }}
      onClick={handleClick}
    >
      <TextareaAutosize
        ref={textAreaRef}
        value={code}
        onChange={handleCodeChange}
        placeholder={`Paste your ${language} code here...`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          resize: 'none',
          padding: '16px',
          fontSize: `${fontSize}px`,
          lineHeight: '1.5',
          fontFamily: 'monospace',
          backgroundColor: 'transparent',
          border: 'none',
          color: 'transparent',
          caretColor: 'white',
          zIndex: 2,
          cursor: 'text',
        }}
      />
      <SyntaxHighlighter
        language={getLanguageAlias(language)}
        style={nightOwl}
        showLineNumbers={showLineNumbers}
        customStyle={{
          margin: 0,
          padding: '16px',
          background: 'transparent',
          minHeight: '200px',
          fontSize: `${fontSize}px`,
          lineHeight: '1.5',
        }}
        wrapLines={true}
      >
        {code || `// Paste your ${language} code here...`}
      </SyntaxHighlighter>
    </Box>
  );
}

export default CodeInput; 