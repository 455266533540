import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function TermsConditions() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/')}
        sx={{ mb: 4 }}
      >
        Back to Home
      </Button>
      
      <Typography variant="h3" gutterBottom>
        Terms & Conditions
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <section>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using ScreenTweak, you accept and agree to be bound by the terms and conditions of this agreement.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" gutterBottom>
            2. Use License
          </Typography>
          <Typography paragraph>
            • You are allowed to use our service for personal and commercial use
            • You must not use our service for any illegal or unauthorized purpose
            • You are responsible for all content you upload and create
          </Typography>
        </section>

        <section>
          <Typography variant="h6" gutterBottom>
            3. Intellectual Property
          </Typography>
          <Typography paragraph>
            The service and its original content, features, and functionality are owned by ScreenTweak and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" gutterBottom>
            4. Limitations
          </Typography>
          <Typography paragraph>
            We shall not be held liable for any damages that result from the use of our service. This includes both direct and indirect damages.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" gutterBottom>
            5. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these terms at any time. We will notify users of any changes by updating the date at the top of this page.
          </Typography>
        </section>
      </Box>
    </Container>
  );
}

export default TermsConditions; 