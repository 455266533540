import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper, Typography, Button, Stack, Grid, Card, CardMedia } from '@mui/material';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageUploader from './ImageUploader';
import EditingTools from './EditingTools';
import Preview from './Preview';
import { toPng } from 'html-to-image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import Crop169Icon from '@mui/icons-material/Crop169';
import Crop75Icon from '@mui/icons-material/Crop75';

function Editor({ onImageChange, initialImage, initialTemplate = 'none', mode }) {
  const navigate = useNavigate();
  const [image, setImage] = useState(initialImage);
  const [code, setCode] = useState('');
  const previewRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedCanvas, setSelectedCanvas] = useState('default');
  const [selectedCanvasSize, setSelectedCanvasSize] = useState('square');

  const canvasOptions = [
    {
      id: 'default',
      name: 'Default',
      gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      preview: 'path/to/default-preview.png'
    },
    {
      id: 'dark',
      name: 'Dark Mode',
      gradient: 'linear-gradient(45deg, #2C3E50 30%, #3498DB 90%)',
      preview: 'path/to/dark-preview.png'
    },
    {
      id: 'gradient',
      name: 'Gradient',
      gradient: 'linear-gradient(45deg, #00C9FF 30%, #92FE9D 90%)',
      preview: 'path/to/gradient-preview.png'
    },
    {
      id: 'minimal',
      name: 'Minimal',
      gradient: 'linear-gradient(45deg, #E0E0E0 30%, #FFFFFF 90%)',
      preview: 'path/to/minimal-preview.png'
    }
  ];

  const canvasSizes = [
    {
      id: 'square',
      name: 'Square (1:1)',
      icon: <CropSquareIcon />,
      width: 600,
      height: 600,
      description: 'Perfect for Instagram'
    },
    {
      id: 'landscape',
      name: 'Landscape (16:9)',
      icon: <Crop169Icon />,
      width: 800,
      height: 450,
      description: 'Great for Twitter/LinkedIn'
    },
    {
      id: 'portrait',
      name: 'Portrait (4:5)',
      icon: <AspectRatioIcon />,
      width: 480,
      height: 600,
      description: 'Ideal for Stories'
    },
    {
      id: 'wide',
      name: 'Wide (2:1)',
      icon: <Crop75Icon />,
      width: 800,
      height: 400,
      description: 'Blog headers'
    }
  ];

  const [editingOptions, setEditingOptions] = useState({
    gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#000000',
    borderOpacity: 1,
    template: initialTemplate,
    shadowBlur: 0,
    shadowSpread: 0,
    shadowColor: '#000000',
    codeFontSize: 14,
    codeLanguage: 'javascript',
    showLineNumbers: true,
    canvasWidth: 600,
    canvasHeight: 600,
  });

  useEffect(() => {
    if (mode === 'code' && !image) {
      setImage('placeholder');
    }
  }, [mode, image]);

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
      onImageChange(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleSave = async () => {
    if (previewRef.current) {
      try {
        const dataUrl = await toPng(previewRef.current, { quality: 0.95 });
        const link = document.createElement('a');
        link.download = 'screenshot-edited.png';
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Error saving image:', err);
      }
    }
  };

  const handleBack = () => {
    setImage(null);
    setCode('');
    onImageChange(false);

    navigate(-1);
  };

  const handleCanvasSelect = (canvasId) => {
    setSelectedCanvas(canvasId);
    setEditingOptions(prev => ({
      ...prev,
      gradient: canvasOptions.find(canvas => canvas.id === canvasId).gradient
    }));
  };

  const handleCanvasSizeSelect = (sizeId) => {
    setSelectedCanvasSize(sizeId);
    const selectedSize = canvasSizes.find(size => size.id === sizeId);

    setEditingOptions(prev => ({
      ...prev,
      canvasWidth: selectedSize.width,
      canvasHeight: selectedSize.height,
      borderRadius: Math.min(prev.borderRadius, Math.min(selectedSize.width, selectedSize.height) / 10),
      shadowSpread: Math.min(prev.shadowSpread, Math.min(selectedSize.width, selectedSize.height) / 8),
    }));

    if (image && image !== 'placeholder') {
      // Optional: Add image resizing logic
    }
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4,
        width: '100%',
        gap: 2
      }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon sx={{ color: '#000' }} />}
          onClick={handleBack}
          sx={{
            backgroundColor: '#fff',
            color: '#000',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
            borderRadius: 2,
            px: 3,
            fontWeight: 500,
          }}
        >
          Back
        </Button>

        <Box sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'nowrap'
        }}>
          {mode !== 'code' && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => e.target.files?.[0] && handleImageUpload(e.target.files[0])}
                style={{ display: 'none' }}
                accept="image/*"
              />
              {!image ? (
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    px: 3,
                    color: '#fff',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    '&:hover': {
                      borderColor: '#fff',
                      backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                  }}
                  startIcon={<FileUploadRoundedIcon />}
                  onClick={handleUploadClick}
                >
                  Upload New
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    px: 3,
                    backgroundColor: '#fff',
                    color: '#000',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                  startIcon={<AddPhotoAlternateIcon />}
                  onClick={handleUploadClick}
                >
                  Change Image
                </Button>
              )}
            </>
          )}
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              borderRadius: 2,
              px: 3,
              backgroundColor: '#fff',
              color: '#000',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
            startIcon={<FileDownloadRoundedIcon />}
          >
            Save Image
          </Button>
        </Box>
      </Box>

      <Paper
        elevation={8}
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 2,
          background: 'rgba(30, 30, 30, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            mb: 2,
            fontWeight: 500
          }}
        >
          Choose Canvas Size
        </Typography>

        <Grid container spacing={2}>
          {canvasSizes.map((size) => (
            <Grid item xs={6} sm={3} key={size.id}>
              <Card
                onClick={() => handleCanvasSizeSelect(size.id)}
                sx={{
                  cursor: 'pointer',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  border: selectedCanvasSize === size.id ? '2px solid #fff' : '1px solid rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.25)',
                  },
                  background: 'rgba(255, 255, 255, 0.05)',
                  height: '140px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  padding: 2,
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                    color: selectedCanvasSize === size.id ? '#fff' : 'rgba(255, 255, 255, 0.7)',
                    '& svg': { fontSize: 40 }
                  }}
                >
                  {size.icon}
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 500
                  }}
                >
                  {size.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    textAlign: 'center',
                    mt: 0.5
                  }}
                >
                  {size.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper
        elevation={8}
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 2,
          background: 'rgba(30, 30, 30, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          overflowX: 'auto',
        }}
      >
        <Box sx={{
          display: 'flex',
          gap: 3,
          minWidth: 'fit-content',
          justifyContent: 'center',
        }}>
          <EditingTools
            options={editingOptions}
            onChange={setEditingOptions}
            mode={mode}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            minHeight: '400px',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 2,
            p: 2,
          }}>
            <Preview
              ref={previewRef}
              image={image}
              code={code}
              options={editingOptions}
              onCodeChange={handleCodeChange}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default Editor; 