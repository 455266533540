import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PrivacyPolicy() {
    const navigate = useNavigate();

    return (
        <Container maxWidth="lg" sx={{ py: 8 }}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/')}
                sx={{ mb: 4 }}
            >
                Back to Home
            </Button>

            <Typography variant="h3" gutterBottom>
                Privacy Policy
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Last updated: {new Date().toLocaleDateString()}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <section>
                    <Typography variant="h6" gutterBottom>
                        1. Information We Collect
                    </Typography>
                    <Typography paragraph>
                        We collect information that you provide directly to us when using ScreenTweak. This includes:
                        • Images you upload for editing
                        • Code snippets you create
                        • Usage analytics and interaction data
                    </Typography>
                </section>

                <section>
                    <Typography variant="h6" gutterBottom>
                        2. How We Use Your Information
                    </Typography>
                    <Typography paragraph>
                        We use the information we collect to:
                        • Provide and improve our services
                        • Analyze how users interact with our platform
                        • Enhance user experience
                        • Fix bugs and optimize performance
                    </Typography>
                </section>

                <section>
                    <Typography variant="h6" gutterBottom>
                        3. Data Storage
                    </Typography>
                    <Typography paragraph>
                        All images and code snippets are processed in your browser. We do not store any of your uploaded content on our servers.
                    </Typography>
                </section>

                <section>
                    <Typography variant="h6" gutterBottom>
                        4. Analytics
                    </Typography>
                    <Typography paragraph>
                        We use Google Analytics to understand how users interact with our platform. This helps us improve our services and user experience.
                    </Typography>
                </section>

                <section>
                    <Typography variant="h6" gutterBottom>
                        5. Contact Us
                    </Typography>
                    <Typography paragraph>
                        If you have any questions about this Privacy Policy, please contact us at support@screentweak.xyz
                    </Typography>
                </section>
            </Box>
        </Container>
    );
}

export default PrivacyPolicy; 