import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, AppBar, Toolbar, Button, Typography, Box, Grid, Paper } from '@mui/material';
import { Link as RouterLink, Routes, Route } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Editor from './components/Editor';
import CodeIcon from '@mui/icons-material/Code';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';

// Initialize GA4 with your measurement ID
ReactGA.initialize('G-MVHTF4WEEW'); // Replace with your GA4 measurement ID

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f8f9ff',
    },
    primary: {
      main: '#000000',
    }
  },
  typography: {
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      color: '#000000',
    },
    subtitle1: {
      fontSize: '1.1rem',
      color: '#666',
      marginBottom: '2rem',
    }
  },
});

function App() {
  const [hasImage, setHasImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const fileInputRef = useRef(null);

  // Track page views
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home Page"
    });
  }, []);

  const handleImageChange = (hasImage, imageData) => {
    setHasImage(hasImage);
    setImageData(imageData);

    // Track image editing event
    ReactGA.event({
      category: "Image Editor",
      action: "Image Edit",
      label: "Image Modified"
    });
  };

  const handleModeSelect = (mode) => {
    setSelectedMode(mode);
    if (mode === 'code') {
      setHasImage(true);
      setImageData(null);
    }

    // Track mode selection
    ReactGA.event({
      category: "User Selection",
      action: "Mode Selected",
      label: mode === 'code' ? 'Code Editor' : 'Image Editor'
    });
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageData(e.target.result);
      setHasImage(true);
      setSelectedMode('image');

      // Track successful image upload
      ReactGA.event({
        category: "Image Upload",
        action: "Upload Success",
        label: file.type,
        value: Math.round(file.size / 1024) // file size in KB
      });
    };

    reader.onerror = () => {
      // Track failed upload
      ReactGA.event({
        category: "Image Upload",
        action: "Upload Failed",
        label: file.type
      });
    };

    reader.readAsDataURL(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleImageUpload(file);

      // Track drag and drop upload
      ReactGA.event({
        category: "Image Upload",
        action: "Drag and Drop",
        label: file.type
      });
    }
  };

  const handleFooterLinkClick = (linkName) => {
    // Track footer link clicks
    ReactGA.event({
      category: "Navigation",
      action: "Footer Link Click",
      label: linkName
    });
  };

  const handleLogoClick = () => {
    // Track logo clicks
    ReactGA.event({
      category: "Navigation",
      action: "Logo Click",
      label: "Home"
    });
  };

  const handleCodeEditorClick = () => {
    // Track code editor selection
    ReactGA.event({
      category: "User Selection",
      action: "Editor Selected",
      label: "Code Editor"
    });
    handleModeSelect('code');
  };

  const handleImageEditorClick = () => {
    // Track image editor selection
    ReactGA.event({
      category: "User Selection",
      action: "Editor Selected",
      label: "Image Editor"
    });
    fileInputRef.current?.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={
          <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar
              position="static"
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: 'none'
              }}
            >
              <Container maxWidth="lg">
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <RouterLink
                      to="/"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      onClick={handleLogoClick}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 800,
                          background: 'linear-gradient(45deg, #000000 30%, #666666 90%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          letterSpacing: '-0.5px'
                        }}
                      >
                        ScreenTweak
                      </Typography>
                    </RouterLink>
                    <Box
                      sx={{
                        bgcolor: '#FF4D4F',
                        color: 'white',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        ml: 1
                      }}
                    >
                      BETA
                    </Box>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: hasImage ? 2 : 8, flex: 1, mb: 6 }}>
              {!hasImage ? (
                <>
                  <Typography variant="h1" align="center" gutterBottom>
                    Create Beautiful Screenshots
                  </Typography>
                  <div style={{ color: '#ff4d4f', fontSize: '24px', margin: '1rem 0', textAlign: 'center' }}>
                    ★★★★★
                  </div>
                  <Typography variant="subtitle1" align="center" sx={{ maxWidth: '800px', mx: 'auto', mb: 6 }}>
                    Create Stunning Screenshots & Shareable Code Snippets for LinkedIn, Twitter, and Facebook.
                  </Typography>

                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={5}>
                      <Paper
                        elevation={4}
                        onClick={handleCodeEditorClick}
                        sx={{
                          p: 4,
                          textAlign: 'center',
                          cursor: 'pointer',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            border: '2px dashed #e0e0e0',
                            borderRadius: 2,
                            p: 4,
                            cursor: 'pointer',
                            '&:hover': {
                              borderColor: '#BB86FC',
                              backgroundColor: 'rgba(187, 134, 252, 0.04)',
                            },
                          }}
                        >
                          <CodeIcon sx={{ fontSize: 60, color: '#BB86FC', mb: 2 }} />
                          <Typography variant="h5" gutterBottom fontWeight="bold">
                            Code Screenshot
                          </Typography>
                          <Typography variant="body1" color="text.secondary" gutterBottom>
                            Create beautiful code snippets with syntax highlighting
                          </Typography>
                          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                            <CodeIcon sx={{ color: '#BB86FC' }} />
                            <Typography variant="body2" color="text.secondary">
                              Click to start coding
                            </Typography>
                          </Box>
                          <Typography variant="caption" color="text.secondary" display="block" mt={1}>
                            Supports: JavaScript, Python, Java, and more
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <Paper
                        elevation={4}
                        sx={{
                          p: 4,
                          textAlign: 'center',
                          cursor: 'pointer',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                          },
                        }}
                        onClick={handleImageEditorClick}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          accept="image/*"
                          onChange={(e) => {
                            if (e.target.files?.[0]) {
                              handleImageUpload(e.target.files[0]);
                            }
                          }}
                        />
                        <Box
                          sx={{
                            border: '2px dashed #e0e0e0',
                            borderRadius: 2,
                            p: 4,
                            cursor: 'pointer',
                            '&:hover': {
                              borderColor: '#03DAC6',
                              backgroundColor: 'rgba(3, 218, 198, 0.04)',
                            },
                          }}
                        >
                          <ImageIcon sx={{ fontSize: 60, color: '#03DAC6', mb: 2 }} />
                          <Typography variant="h5" gutterBottom fontWeight="bold">
                            Image Screenshot
                          </Typography>
                          <Typography variant="body1" color="text.secondary" gutterBottom>
                            Create beautiful screenshots with custom frames and effects
                          </Typography>
                          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                            <CloudUploadIcon sx={{ color: '#03DAC6' }} />
                            <Typography variant="body2" color="text.secondary">
                              Drop an image here or click to upload
                            </Typography>
                          </Box>
                          <Typography variant="caption" color="text.secondary" display="block" mt={1}>
                            Supports: JPG, PNG, JPEG, WEBP
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Editor
                  onImageChange={handleImageChange}
                  initialImage={imageData}
                  initialTemplate={selectedMode === 'code' ? 'code' : 'none'}
                  mode={selectedMode}
                />
              )}
            </Container>

            <Box
              component="footer"
              sx={{
                py: 4,
                px: 2,
                mt: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 4,
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                      pr: 4
                    }}
                  >
                    © 2024 ScreenTweak. All rights reserved.
                  </Typography>
                  <RouterLink
                    to="/privacy"
                    onClick={() => handleFooterLinkClick('Privacy Policy')}
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.6)',
                      '&:hover': {
                        color: 'rgba(0, 0, 0, 0.8)',
                        textDecoration: 'underline',
                      }
                    }}
                  >
                    Privacy Policy
                  </RouterLink>
                  <RouterLink
                    to="/terms"
                    onClick={() => handleFooterLinkClick('Terms & Conditions')}
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.6)',
                      '&:hover': {
                        color: 'rgba(0, 0, 0, 0.8)',
                        textDecoration: 'underline',
                      }
                    }}
                  >
                    Terms & Conditions
                  </RouterLink>
                </Box>
              </Container>
            </Box>
          </Box>
        } />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App; 